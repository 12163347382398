<template>
  <v-container
    bg
    fill-height
    grid-list-md
    text-xs-center
  >
    <v-layout
      row
      wrap
      align-center
    >
      <v-flex class="center">
        <v-sheet
          v-if="logo"
          width="300"
          style="margin: auto"
          color="transparent"
        >
          <img
            src="@/assets/images/favicon/evalmee/android-chrome-512x512.png"
            alt="Evalmee"
            class="evalmee-logo mb-10"
          >
          <v-progress-linear
            height="8"
            indeterminate
            color="primary"
            rounded
          />
        </v-sheet>

        <v-progress-circular
          v-else
          :size="100"
          :width="7"
          color="primary"
          indeterminate
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Loader2",
  props: {
    logo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
  .evalmee-logo {
    height: 70px;
  }
</style>
